<template>
  <v-app>
    <div id="signin" class="d-flex flex-column align-center justify-center">
      <img
        src="../assets/finman-logo.png"
        alt
        class="d-block mt-12 mb-6"
        style="height: 60px;"
      />
      <v-card class="elevation-24 mx-6 mb-6 mx-sm-0">
        <v-toolbar color="gray" dark flat class="title-bar">
          <v-toolbar-title>Kullanıcı Girişi</v-toolbar-title>
        </v-toolbar>
        <v-alert
          dark
          v-if="alert"
          :type="error ? 'error' : 'success'"
          style="border-radius: 0;"
          >{{ message }}</v-alert
        >
        <v-form ref="form" @submit.prevent="signin()">
          <v-card-text>
            <v-text-field
              id="email"
              v-model="form.email"
              label="e-Posta"
              name="email"
              prepend-icon="person"
              type="text"
              required
              :rules="emailRules"
            />
            <v-text-field
              id="password"
              v-model="form.password"
              label="Parola"
              name="password"
              prepend-icon="lock"
              type="password"
              required
              :rules="passwordRules"
            />
          </v-card-text>
          <v-card-actions class="mx-2 mb-8">
            <v-btn color="primary" type="submit" dark rounded block
              >GİRİŞ YAP</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
      <div class="footer">
        <p>© 2020 FİN-MAN A.Ş. Tüm hakları saklıdır.</p>
      </div>
    </div>
  </v-app>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  data: () => ({
    form: {
      email: "",
      password: ""
    },
    alert: false,
    message: "",
    error: false
  }),
  computed: {
    emailRules() {
      return [
        v => !!v || "Lütfen e-postanızı yazınız",
        v => /.+@.+\..+/.test(v) || "Geçersiz e-posta adresi"
      ];
    },
    passwordRules() {
      return [v => !!v || "Lütfen parolanızı yazınız"];
    }
  },
  methods: {
    validate() {
      const form = this.$refs.form;
      return form.validate();
    },
    reset() {
      const form = this.$refs.form;
      form.reset();
    },
    resetValidation() {
      const form = this.$refs.form;
      form.resetValidation();
    },
    signin() {
      if (this.validate()) {
        if (this.form.email == "admin@finman.com.tr" && this.form.password == "F!nman26") {
          this.$store.commit("signIn");
          this.$router.push({ name: "Recordings" });
        } else {
          this.message = "Kullanıcı adı / parola doğrulanamadı.";
          this.alert = true;
          this.error = true;
        }  
      }
    },
    getErrorMessage(code) {
      let msg = "";
      switch (code) {
        default:
          msg = "Kullanıcı adı / parola doğrulanamadı.";
      }
      return msg;
    }
  },
  mounted() {
    if (this.$store.state.signIn) {
      this.$router.push({name: "Recordings"});
    }
  }
});
</script>

<style lang="scss">
#signin {
  width: 100%;
  height: 100%;
  background-color: #eee;

  .v-card {
    width: 90%;
    max-width: 400px;
    border-radius: 15px;
  }

  .title-bar {
    background: linear-gradient(#666666, #000000);
    border: none;
  }

  .footer {
    width: 90%;
    margin: 30px auto;
    bottom: 20px;
    text-align: center;

    .links > a {
      $a-size: 36px;

      display: inline-block;
      background-color: rgba(0, 0, 0, 0.1);
      width: $a-size;
      height: $a-size;
      line-height: $a-size;
      border-radius: 50%;
      text-align: center;
      margin: 5px 10px;
      color: #fff;
      text-decoration: none;

      &:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    & > p {
      font-size: 0.8rem;
      margin: 10px 0 0;
    }
  }
}
</style>
